<template>
  <div class="mainform">
    <div class="form">
      <el-form  class="content bascform" ref="addpayplan" :model="form"  label-width="100px">
        <div class="col col4">
          <el-form-item label="供应商" prop="supplier" >
            <el-select size="small" filterable
            :remote-method="requestSupplierOption"
            :loading="supplierLoading"
            @change="supChange"
            v-model="form.supplier">
              <el-option v-for="(item) in supplierOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
        </div>
        <div class="col col4">
          <el-form-item label="采购品类" prop="cgModel" label-width="100px">
            <el-select size="small" filterable
            :loading="supplierLoading"
            @change="cgChange"
            v-model="form.cgModel">
              <el-option v-for="(item) in bankData"
              :key="item.value"
              :label="item.value+'-'+item.label"
              :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
        </div>
        <div class="col col4">
          <el-form-item label="实际返修期" prop="ghqData" label-width="100px">
            <el-input v-model="form.ghqData" @input="(val)=>{form.ghqData = val.replace(/\D|^[0]+/g,'')}" placeholder=""></el-input>
          </el-form-item>
        </div>
        <div class="col col4">
          <el-form-item label="发生季度" prop="fsData" label-width="100px">
            <el-input v-model="form.fsData" @input="(val)=>{form.fsData = val.replace(/[^\d\-]/g,'')}" placeholder=""></el-input>
          </el-form-item>
        </div>
      </el-form>
    </div>
  </div>
</template>

<script>
import Component from '@/assets/js/components.js'
import { requestForm } from '@/assets/js/http.js'

export default {
  name: 'KeyIssuesDialog',
  components: { ...Component },
  data: function () {
    return {
      form: {},
      supplierOptions: [],
      supplierLoading: false,
      dialogShow: false,
      bankShow: false,
      companyCode: '',
      bankData: [
        { label: '主板、板卡', value: 'PL001' },
        { label: 'OEM整机、防火墙等', value: 'PL002' },
        { label: '硬盘', value: 'PL003' },
        { label: 'SSD', value: 'PL004' },
        { label: '盘阵', value: 'PL005' },
        { label: '光纤HBA卡', value: 'PL006' },
        { label: '光纤模块', value: 'PL007' },
        { label: '外设', value: 'PL008' },
        { label: '自制品', value: 'PL009' },
        { label: '网卡', value: 'PL010' },
        { label: '线缆', value: 'PL011' },
        { label: 'CPU', value: 'PL012' },
        { label: 'GPU、显卡', value: 'PL013' },
        { label: '结构件、附件', value: 'PL014' },
        { label: '内存', value: 'PL015' },
        { label: '背板', value: 'PL016' },
        { label: '中板', value: 'PL017' },
        { label: '交换机', value: 'PL018' },
        { label: 'RAID卡', value: 'PL019' },
        { label: '电源', value: 'PL020' },
        { label: '风扇', value: 'PL021' },
        { label: '散热器', value: 'PL022' },
        { label: '包材', value: 'PL023' },
        { label: '标签', value: 'PL024' },
        { label: '资材类（固资、费用、礼品、印刷品等）', value: 'PL025' },
        { label: '外购软件', value: 'PL026' },
        { label: '服务', value: 'PL027' },
        { label: '商品', value: 'PL028' },
        { label: '第三方采购物料', value: 'PL029' },
        { label: '研发购原材料', value: 'PL030' },
        { label: '仙络原材料', value: 'XL001' }
      ]
    }
  },
  created () {
    this.requestSupplierOption()
  },
  methods: {
    // 获取供应商
    requestSupplierOption (val) {
      this.supplierLoading = true
      this.supplierOptions = []
      requestForm('/api/supplier/basicData/getForSelect', 'post', { supplier: val }).then((Response) => {
        if (Response.code === '200') {
          for (const item of Response.data) {
            this.supplierOptions.push({
              key: `${item.name}`,
              label: `${~~item.erpCode}-${item.name}`,
              value: `${item.name}`,
              erpCode: `${~~item.erpCode}`
            })
          }
        }
        this.supplierLoading = false
      })
    },
    supChange (data) {
      for (const item of this.supplierOptions) {
        if (item.value === data) {
          this.form.supplierCode = item.erpCode
        }
      }
    },

    // 获取采购品类
    cgChange (data) {
      for (const item of this.bankData) {
        if (item.value === data) {
          this.form.cgModelName = item.label
        }
      }
    }
  }
}
</script>

<style scoped lang="scss">
@import '@/assets/css/tableList.scss';
@import '@/assets/css/elForm.scss';
.mainform{
  .form{
    padding: 0px 0px;
  }
}
</style>
